import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { disable } from 'gatsby/dist/schema/infer/inference-metadata';
import Layout from '../components/layout';
import blogStyles from './blog.module.scss';
import Head from '../components/head';

export default function BlogPage() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head title="Blog" />
      <h1>./Blog</h1>
      <ol className={blogStyles.posts}>
        {data.allContentfulBlogPost.edges.map((edge) => (
          <li className={blogStyles.post}>
            <Link to={`/blog/${edge.node.slug}`}>
              <h2>{edge.node.title}</h2>
{/*
              <p>{edge.node.publishedDate}</p>
*/}
            </Link>
          </li>
        ))}
      </ol>
    </Layout>
  );
}

// generate slug for each post  gatsby.md -> gatsby -> /blog/gatsby
// generate blog post page template
// generate new page for each post
